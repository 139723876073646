import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Landing from "./Landing";
import ActionHandler from "./ActionHandler";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#414042"
    },
    secondary: {
      main: "#ffdd00"
    },
    error: {
      500: "#c40000"
    }
  }
});

const _url = new URL(window.location.href);
const mode = _url.searchParams.get("mode");
const oobCode = _url.searchParams.get("oobCode");
const continueUrl = _url.searchParams.get("continueUrl");

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    {mode && oobCode ? (
      <ActionHandler mode={mode} oobCode={oobCode} continueUrl={continueUrl} />
    ) : (
      <Landing />
    )}
  </MuiThemeProvider>,
  document.getElementById("root")
);
