import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar
} from "@material-ui/core";

import GoogleMaps from "./GoogleMaps";
import logo from "./static/dark.svg";

const BASE_URL = "https://camarasviales.com";

const styles = theme => ({
  snackbar: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: "1rem"
  },
  logo: {
    width: "16rem"
  },
  parent: {
    textAlign: "center",
    padding: "0 2rem"
  },
  list: {
    borderRadius: "0.2rem",
    border: "solid 1px #0002",
    padding: 0,
    marginTop: "1rem",
    minHeight: "4.2rem"
  }
});

const Landing = ({ classes }) => {
  const [state, setState] = useState({
    latitude: null,
    longitude: null,
    data: []
  });
  const { latitude, longitude, data } = state;

  const didMount = async () => {
    const location = await fetch(`${BASE_URL}/api/base/location`);
    const { latitude, longitude } = await location.json();

    const countries = await fetch(`${BASE_URL}/api/countries`);
    const data = await countries.json();

    setState({ latitude, longitude, data });
  };

  useEffect(() => {
    didMount();
  }, []);

  return (
    <Fragment>
      {latitude && (
        <GoogleMaps longitude={longitude} latitude={latitude} zoom={14} />
      )}
      <Dialog open={true} maxWidth="xs">
        <DialogContent className={classes.root}>
          <Grid spacing={24} justify="center" container>
            <Grid xs={12} className={classes.parent} item>
              <img src={logo} alt="Cámaras Viales" className={classes.logo} />
            </Grid>
            <Grid xs={12} item>
              <Typography variant="subtitle1">
                Por favor seleccione su país:
              </Typography>
            </Grid>
          </Grid>
          <List className={classes.list}>
            {data.map(({ alias, name }) => (
              <Fragment>
                <ListItem
                  button
                  key={alias}
                  onClick={() =>
                    (document.location.href = `https://www.camarasviales${alias}.com`)
                  }
                >
                  <Avatar>
                    <img
                      src={require(`./static/countries/${alias}.svg`)}
                      alt={name}
                      style={{ height: "2.5rem" }}
                    />
                  </Avatar>
                  <ListItemText
                    primary={name}
                    secondary={`www.camarasviales${alias}.com`}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(Landing);
