import React from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";

const GoogleMapsContainer = ({
  google,
  longitude,
  latitude,
  children,
  zoom,
  ...props
}) => (
  <Map
    initialCenter={{ lng: longitude, lat: latitude }}
    mapTypeControl={false}
    google={google}
    zoom={zoom}
    {...props}
  >
    {children && children(google)}
  </Map>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyDjzaEDBcdJRGle-yVAmOJ9-_47MGzIui8"
})(GoogleMapsContainer);
