import React from "react";
import styled from "styled-components";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import logo from "./static/dark.svg";
import bg from "./static/bg.jpg";

var __config = {
  apiKey: "AIzaSyDs0kVj3Fr2JeFSHjpYsRH6Bsc9e0_iyp4",
  authDomain: "camaras-viales-access.firebaseapp.com",
  databaseURL: "https://camaras-viales-access.firebaseio.com",
  projectId: "camaras-viales-access",
  storageBucket: "camaras-viales-access.appspot.com",
  messagingSenderId: "353078581810",
  appId: "1:353078581810:web:728be1d99556726e"
};

const validator = value => {
  var strong = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  var medium = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  if (strong.test(value)) {
    return "strong";
  }
  if (medium.test(value)) {
    return "medium";
  }
  return "low";
};

const Error = styled.span`
  background: #f003;
  font-size: 12px;
  padding: 10px;
  border: solid 1px #f003;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
  color: darkred;
  font-family: system-ui;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 99999;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: url("${() => bg}") no-repeat center center fixed;
  background-size: cover;
`;

const Logo = styled.img`
  width: 80%;
  margin-bottom: 40px;
  margin-left: 10%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  box-shadow: 0 4px 20px 10px #0004;
  background: white;
  border-radius: 10px;
  padding: 40px;

  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;
const InputField = styled.input`
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #0003;
  background: #0000000e;
  box-shadow: 0px 1px 1px #0001;
  margin: 4px 0;
  outline: none;
  &.low {
    box-shadow: inset 0px -2px 0px orangered;
  }

  &.medium {
    box-shadow: inset 0px -2px 0px gold;
  }

  &.strong {
    box-shadow: inset 0px -2px 0px lawngreen;
  }
`;
const SignIn = styled.button`
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #0003;
  background: #ffdd00cc;
  box-shadow: 0px 1px 1px #0001;
  margin-top: 20px;
  line-height: 28px;
  flex: 1;
  cursor: pointer;
  outline: none;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    background: #ffdd00;
  }

  &.cancel {
    border: none;
    background: white;
    box-shadow: none;
    flex: 1;
  }

  &.submit {
    flex: 2;
  }
`;

class Login extends React.Component {
  state = {
    password: "",
    strength: "",
    code: null,
    done: false
  };
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      firebase.initializeApp(__config);
      firebase.auth().languageCode = "es";
    }
  }
  _change = ({ target: { value, name } }) => this.setState({ [name]: value });
  _verifyEmail = () => {
    firebase
      .auth()
      .applyActionCode(this.props.oobCode)
      .then(this._redirect)
      .catch(error =>
        this.setState({
          code:
            "Error al procesar su solicitud, por favor solicite un nuevo código de verificación"
        })
      );
  };
  _resetPassword = () => {
    const auth = firebase.auth();
    const { password } = this.state;
    const { oobCode } = this.props;

    auth
      .verifyPasswordResetCode(oobCode)
      .then(email =>
        auth.confirmPasswordReset(oobCode, password).then(this._redirect)
      )
      .catch(error =>
        this.setState({
          code:
            "Error al procesar su solicitud, por favor solicite un nuevo código de reseteo de contraseña"
        })
      );
  };
  _process = evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const { mode } = this.props;

    switch (mode) {
      case "resetPassword":
        this._resetPassword();
        break;
      case "verifyEmail":
        this._verifyEmail();
        break;
      default:
    }
  };
  _redirect = () =>
    this.setState(
      { code: "Usted será redirigido en 5 segundos...", done: true },
      () =>
        setTimeout(() => {
          window.location.href = this.props.continueUrl || window.location.href;
        }, 5000)
    );
  render() {
    const { password, code, done } = this.state;
    const { mode } = this.props;

    const strength = password.trim() !== "" ? validator(password) : false;

    let text = "";
    let pwd = false;

    switch (mode) {
      case "resetPassword":
        text = "Cambiar Contraseña";
        pwd = true;
        break;
      case "verifyEmail":
        text = "Verificar Correo Electrónico";
        break;
      default:
    }
    return (
      <Wrapper>
        <Form onSubmit={this._process}>
          <div>
            <Logo src={logo} alt={`Cámaras Viales`} />
          </div>
          {pwd && (
            <InputField
              type="password"
              name="password"
              value={password}
              placeholder="Nueva Contraseña"
              onChange={this._change}
              className={strength}
              disabled={done}
            />
          )}
          <SignIn
            type="submit"
            disabled={done || (pwd && (!strength || strength === "low"))}
          >
            {text}
          </SignIn>
          {code && <Error>{code}</Error>}
        </Form>
      </Wrapper>
    );
  }
}

export default Login;
